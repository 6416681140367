import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/white-logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/twitter.svg";

export const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();

  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/company/the-petaverse"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon1} alt="Icon" />
              </a>
              <a
                href="https://web.facebook.com/petaverseng"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon2} alt="Icon" />
              </a>
              <a
                href="https://twitter.com/the_petaverse"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon3} alt="icon" />
              </a>
            </div>
            <p>Copyright 2022 - {year}. All Rights Reserved</p>
            <p>info@the-petaverse.com</p>
            <p> +2348105060169</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
