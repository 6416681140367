import React, { useEffect, useState } from "react";
import LoginImage from "../../assets/img/feedback.png";
import { useForm } from "react-hook-form";
// import { toast, Bounce } from "react-toastify";
import "./ContactUs.css";
import SideBar from "../../components/SideBar";
import axios from "axios";

const ContactUs = ({ openSidebar, closeSidebar }) => {
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [networkError, setNetworkError] = useState({});

  const {
    register,
    handleSubmit,
    reset,
    // watch,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    axios
      .post(
        "https://petaverse-web-app-backend.onrender.com/api/v1/user/contact/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setShowToastMessage(true);
        }
      })
      .then((error) => {
        if (error) {
          setNetworkError();
        }
      });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ fullName: "", phoneNumber: "", email: "", request_details: "" });
    }
  }, [showToastMessage, isSubmitSuccessful, reset, networkError]);

  return (
    <div className="connect-main-wrapper">
      {openSidebar === true && <SideBar closeSidebar={closeSidebar} />}
      <div className="register-main-container">
        <div className="register-iamge-wrapper">
          <img src={LoginImage} alt="" className="register-image" />
        </div>
        <div className="register-inner-form-wrapper">
          {showToastMessage === true ? (
            <p className="success-message">Your request has been submitted</p>
          ) : (
            <p>Drop us a message and we'll revert</p>
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="register-form-wrapper"
          >
            <label>
              <input
                type="text"
                className="register-main-text-input"
                placeholder="Full name"
                {...register("fullName", {
                  required: "Full name is required",
                })}
              />
              {errors.fullName && (
                <p className="input-error-message">{errors.fullName.message}</p>
              )}
            </label>
            <label>
              <input
                type="text"
                className="register-main-text-input"
                placeholder="Phone number"
                {...register("phoneNumber", {
                  required: "Phone number is required",
                })}
              />
              {errors.phoneNumber && (
                <p className="input-error-message">
                  {errors.phoneNumber.message}
                </p>
              )}
            </label>
            <label>
              <input
                type="email"
                className="register-main-text-input"
                placeholder="Email(example@example.com)"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <p className="input-error-message">{errors.email.message}</p>
              )}
            </label>

            <label>
              <textarea
                type="text"
                className="register-main-text-input textarea-container"
                placeholder="Details here"
                {...register("request_details", {
                  required: "Message is required",
                })}
              />
              {errors.request_details && (
                <p className="input-error-message">
                  {errors.request_details.message}
                </p>
              )}
            </label>
            <input
              type="submit"
              disabled={!isValid}
              className="register-main-form-btn"
              // onClick={handleVerifyRedirect}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
