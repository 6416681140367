import React from "react";
import "../App.css";

import { Banner } from "../components/Banner";
import Transform from "../components/Transform";
import TrustedPartners from "../components/TrustedPartners";

import SideBar from "../components/SideBar";

function Home({ openSidebar, closeSidebar }) {
  return (
    <>
      {openSidebar === true && <SideBar closeSidebar={closeSidebar} />}

      <Banner />
      <Transform />
      <TrustedPartners />
    </>
  );
}

export default Home;
