import React from "react";
import { Services } from "../components/Services";
import SideBar from "../components/SideBar";
import ServiceTopBanner from "../components/ServiceTopBanner";
import TopBannerImage from "../assets/img/scale.png";

const OurServices = ({ openSidebar, closeSidebar }) => {
  return (
    <div className="our-service-container">
      {openSidebar === true && <SideBar closeSidebar={closeSidebar} />}
      <ServiceTopBanner
        miniMessage={"Custom software development"}
        mainTitle={"Scale your software development"}
        subTitle={"effortlessly."}
        para={
          "We deliver high-quality software with top-tier nearshore talent."
        }
        btnText={"Schedule a call"}
        sectionImage={TopBannerImage}
      />
      <Services />
    </div>
  );
};

export default OurServices;
