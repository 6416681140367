import React from "react";
import { Link } from "react-router-dom";

const InfoSecTopBanner = ({
  mainTitle,
  subTitle,
  para,
  btnText,
  sectionImage,
  miniMessage,
}) => {
  return (
    <div className="info-top-banner-container">
      <div className="service-top-banner-left">
        <h3 className="mini-message-wrapper">{miniMessage}</h3>
        <h1 className="service-top-banner-header">{mainTitle}</h1>
        <h2 className="service-top-banner-header-below">{subTitle}</h2>
        <h3 className="service-top-banner-para">{para}</h3>
        <Link to="/connect" className="header-btn service-top-banner-btn">
          {btnText}
        </Link>
      </div>
      <div className="service-top-banner-right">
        {/* <img
          src={sectionImage}
          className="service-top-banner-img"
          alt="banner"
        /> */}
      </div>
    </div>
  );
};

export default InfoSecTopBanner;
