import React from "react";
import TranformImage from "../assets/img/transform.jpg";

const Transform = () => {
  return (
    <div className="transform-container">
      <div className="tranform-image-container">
        <img
          src={TranformImage}
          className="transform-image-wrapper"
          alt="transform"
        />
      </div>
      <div className="transform-details-wrapper">
        <h2 className="transform-first-head">About Petaverse Limited</h2>
        <h2 className="transform-second-text">
          Transforming Complexity into Simplicity
        </h2>
        <p>
          Welcome to Petaverse Limited, where technology meets innovation, and
          solutions are crafted to transform businesses. Since our inception,
          we've been driven by a singular mission: to empower organizations with
          tailor-made software solutions that drive growth, efficiency, and
          success. Our company was born out of the desire to bridge the gap
          between cutting-edge technology and real-world challenges. With a
          shared vision, our team set out to revolutionize the software
          landscape by delivering solutions that not only meet but exceed
          expectations.
        </p>
      </div>
    </div>
  );
};

export default Transform;
