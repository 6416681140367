import React from "react";
import PetaLogo from "../assets/img/round-logo.png";
import UnparralledImage from "../assets/img/unparalleled_icon.png";
import InnovationImage from "../assets/img/innovation_icon.png";
import TailoredImage from "../assets/img/tailored_icon.png";
import QualityImage from "../assets/img/quality_icon.png";
import ClientImage from "../assets/img/clients_icon.png";
import EndToEndImage from "../assets/img/end_end_icon.png";

const TrustedPartners = () => {
  return (
    <div className="trusted-main-container">
      <div className="trusted-main-header-container">
        <h1>A trusted partner committed to delivering exceptional results</h1>
      </div>
      <div className="truested-line-below">
        <div className="trusted-card-container right-aligned">
          <div className="card-inner-image-container">
            <img src={ClientImage} className="card-inner-image" alt="para" />
          </div>
          <h3>Tech Start-up Ignite</h3>
          <h4>
            With our BUILD with NO DOWN PAYMENT program designed for Tech
            start-ups and Founders. Let's Talk.
          </h4>
        </div>
        <div className="trusted-card-container left-aligned">
          <div className="card-inner-image-container image-aligned-left">
            <img
              src={EndToEndImage}
              className="card-inner-image big-image-style"
              alt="para"
            />
          </div>
          <h3>Pen-testing Solutions</h3>
          <h4>
            Petaverse continues to lead the way in providing best-in-class
            security solutions for your applications.
          </h4>
        </div>
      </div>
      <div className="trusted-top-list-wrapper">
        <div className="trusted-line-one">
          <div className="trusted-card-container right-aligned">
            <div className="card-inner-image-container">
              <img
                src={UnparralledImage}
                className="card-inner-image big-image-style"
                alt="para"
              />
            </div>
            <h3>Unparalleled Expertise</h3>
            <h4>
              We bring a diverse skill set to the table, ensuring that your
              project is in the hands of seasoned professionals.
            </h4>
          </div>
          <div className="trusted-card-container right-aligned">
            <div className="card-inner-image-container">
              <img
                src={InnovationImage}
                className="card-inner-image"
                alt="para"
              />
            </div>
            <h3>Innovation in Every Line of Code</h3>
            <h4>
              We continuously explore new technolo- gies, methodologies, and
              trends to infuse your projects with creativity and ingenuity.
            </h4>
          </div>
        </div>
        <div className="truested-middle-logo">
          <img src={PetaLogo} className="center-logo-wrapper" alt="para" />
        </div>
        <div className="trusted-line-two">
          <div className="trusted-card-container left-aligned">
            <div className="card-inner-image-container image-aligned-left">
              <img
                src={TailoredImage}
                className="card-inner-image big-image-style"
                alt="para"
              />
            </div>
            <h3>Tailored Solutions for Your Success</h3>
            <h4>
              Our approach involves working closely with you to comprehend your
              needs and crafting solutions that align perfectly with your goals.
            </h4>
          </div>
          <div className="trusted-card-container left-aligned">
            <div className="card-inner-image-container image-aligned-left">
              <img
                src={QualityImage}
                className="card-inner-image big-image-style"
                alt="para"
              />
            </div>
            <h3>Quality that Inspires Trust</h3>
            <h4>
              Rigorous testing, stringent quality control, and a focus on detail
              ensure that what we deliver is nothing short of excellence.
            </h4>
          </div>
        </div>
      </div>
      <div className="truested-line-below">
        <div className="trusted-card-container right-aligned">
          <div className="card-inner-image-container">
            <img src={ClientImage} className="card-inner-image" alt="para" />
          </div>
          <h3>Client-Centric Approach</h3>
          <h4>
            Through open communication and collaboration, we ensure that your
            software solution aligns per- fectly with your business objectives.
          </h4>
        </div>
        <div className="trusted-card-container left-aligned">
          <div className="card-inner-image-container image-aligned-left">
            <img
              src={EndToEndImage}
              className="card-inner-image big-image-style"
              alt="para"
            />
          </div>
          <h3>End-to-End Solutions</h3>
          <h4>
            Our holistic approach ensures a seamless expe- rience and a software
            solution that evolves with your needs.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default TrustedPartners;
