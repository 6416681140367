import React from "react";
import { Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { Link } from "react-router-dom";

export const Banner = () => {
  return (
    <div className="banner" id="home">
      <div>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              <div className="header-wrapper">
                <p className="header-first-text">
                  WELCOME TO PETAVERSE LIMITED
                </p>
                <h2 className="header-second-text">
                  Where Ideas Transform Into Software
                </h2>
                <p>
                  We're dedicated to pushing the boundaries of technology and
                  creating software solutions that make a difference.
                </p>

                <Link to="/connect" className="header-btn">
                  Schedule a Consultation
                </Link>
              </div>
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  <img src={headerImg} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </div>
    </div>
  );
};
