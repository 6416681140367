import React, { useState, useEffect } from "react";
import logo from "../assets/img/white-logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/twitter.svg";
import MenuIcon from "../assets/img/burger-menu.png";
import CloseIcon from "../assets/img/close_icon.png";
import { Link } from "react-router-dom";

export const NavBar = ({ open, close, openSidebar }) => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <div expand="md" className={scrolled ? "scrolled" : ""}>
      <div className="nav-main-wrapper">
        <div className="logo-wrapper">
          <div href="/" className="nav-logo-wrapper">
            <img src={logo} alt="Logo" width={10} className="nav-log" />
          </div>
        </div>
        {openSidebar === false && (
          <div className="burger-menu-wrapper" onClick={open}>
            {close && (
              <img src={MenuIcon} className="nav-mobile-toggle" alt="toggle" />
            )}
          </div>
        )}
        {openSidebar === true && (
          <div className="burger-menu-wrapper" onClick={close}>
            {open && (
              <img
                src={CloseIcon}
                className="nav-mobile-toggle close-toggle"
                alt="close-toggle"
              />
            )}
          </div>
        )}
        <div className="nav-main-list" id="basic-navbar-nav">
          <ul className="inner-menu-list">
            <li>
              <Link
                to="/"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/software-services"
                className={
                  activeLink === "service"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("service")}
              >
                Software Dev
              </Link>
            </li>
            <li>
              <Link
                to="/infosec"
                className={
                  activeLink === "service"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("service")}
              >
                Pentesting
              </Link>
            </li>
            <li>
              <Link
                to="/connect"
                className={
                  activeLink === "connect"
                    ? "active navbar-link"
                    : "navbar-link siderbar-link"
                }
                onClick={() => onUpdateActiveLink("connect")}
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <span className="navbar-text">
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/company/the-petaverse"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon1} alt="" />
              </a>

              <a
                href="https://web.facebook.com/petaverseng"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon2} alt="" />
              </a>
              <a
                href="https://twitter.com/the_petaverse"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon3} alt="" />
              </a>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};
