import React, { useState } from "react";
import { Link } from "react-router-dom";

const SideBar = ({ closeSidebar }) => {
  const [activeLink, setActiveLink] = useState("home");

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };
  return (
    <div className="sidebar-main-wrapper">
      <div class="inner-side-bar">
        <ul className="sider-menu-list">
          <li onClick={closeSidebar}>
            <Link
              to="/"
              className={
                activeLink === "home" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("home")}
            >
              Home
            </Link>
          </li>
          <li onClick={closeSidebar}>
            <Link
              to="/software-services"
              className={
                activeLink === "service" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("service")}
            >
              Software Dev
            </Link>
          </li>
          <li onClick={closeSidebar}>
            <Link
              to="/infosec"
              className={
                activeLink === "/academy" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("academy")}
            >
              Pentesting
            </Link>
          </li>
          <li onClick={closeSidebar}>
            <Link
              to="/connect"
              className={
                activeLink === "connect"
                  ? "active navbar-link"
                  : "navbar-link siderbar-link"
              }
              onClick={() => onUpdateActiveLink("connect")}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
