import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import { Footer } from "./components/Footer";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import { useState } from "react";
import OurServices from "./pages/OurServices";
import ContactUs from "./pages/contactUs/ContactUs";
import InfoSec from "./pages/InfoSec";

function App() {
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };
  return (
    <div>
      <BrowserRouter>
        <NavBar
          open={handleOpenSidebar}
          close={handleCloseSidebar}
          openSidebar={openSidebar}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                openSidebar={openSidebar}
                closeSidebar={handleCloseSidebar}
              />
            }
          />
          <Route
            path="/software-services"
            element={
              <OurServices
                openSidebar={openSidebar}
                closeSidebar={handleCloseSidebar}
              />
            }
          />
          <Route
            path="/connect"
            element={
              <ContactUs
                openSidebar={openSidebar}
                closeSidebar={handleCloseSidebar}
              />
            }
          />
          <Route
            path="/infosec"
            element={
              <InfoSec
                openSidebar={openSidebar}
                closeSidebar={handleCloseSidebar}
              />
            }
          />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
