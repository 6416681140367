import React from "react";
import SideBar from "../components/SideBar";
import HackingImage from "../assets/img/hack.png";
import SecServices from "../components/SecServices";
import InfoSecTopBanner from "../components/InfoSecTopBanner";

const InfoSec = ({ openSidebar, closeSidebar }) => {
  return (
    <div className="our-service-container">
      {openSidebar === true && <SideBar closeSidebar={closeSidebar} />}
      <InfoSecTopBanner
        miniMessage={"Get a real-world look at"}
        mainTitle={"how attackers"}
        subTitle={"could exploit your vulnerabilities."}
        para={"Plus guidance on how to stop them—with our pen testing services"}
        btnText={"Schedule a call"}
        sectionImage={HackingImage}
      />
      <SecServices />
    </div>
  );
};

export default InfoSec;
