import React from "react";
import OutsourcingImage from "../assets/img/outsourcing.jpg";
import CustomAccordion from "./CustomAccordion";
const SecServices = () => {
  return (
    <div className="services-main-container" id="#service">
      <div className="sec-services-header-wrapper">
        <h2 className="services-header-small services-header-big">
          Penetration Testing Services
        </h2>
        <p className="infosec-upper-text">
          In security as in life, the hardest weaknesses to pinpoint are your
          own. Fortunately, we have no problem thoroughly documenting all of
          your flaws. In fact, it’s kind of our job. And that’s a good thing:
          Knowing your vulnerabilities and the ways in which attackers could
          exploit them, is one of the greatest insights you can get in improving
          your security program. With that in mind, Petaverse’s Penetration
          Testing Services team will simulate a real-world attack on your
          networks, applications, devices, and/or people to demonstrate the
          security level of your key systems and infrastructure and show you
          what it will take to strengthen it. Much like your mom, we don't
          highlight your failings because it bothers you, we do it because we
          care.
        </p>
      </div>
      <div className="transform-container">
        <div className="tranform-image-container">
          <img
            src={OutsourcingImage}
            className="transform-image-wrapper"
            alt="securing"
          />
        </div>
        <div className="transform-details-wrapper">
          <h2 className="transform-second-text">
            What to fix, <br />
            when, and how to fix it
          </h2>
          <p>
            The best you can hope for from most penetration tests is a long list
            of problems with little context on how to fix them or where to
            start. Helpful, right? Petaverse provides a prioritized list of
            issues, based on the exploitability and impact of each finding using
            an industry-standard ranking process.
          </p>
        </div>
      </div>

      <CustomAccordion />
    </div>
  );
};

export default SecServices;
