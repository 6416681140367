import React from "react";
import "react-multi-carousel/lib/styles.css";
import CustomImage from "../assets/img/custom.jpg";
import MobileApp from "../assets/img/mobile.jpg";
import OutsourcingImage from "../assets/img/outsourcing.jpg";

export const Services = () => {
  return (
    <div className="services-main-container" id="#service">
      <div className="services-header-wrapper">
        <h2 className="services-header-small">Our Services</h2>
        <h2 className="services-header-big">Services We Offer</h2>
      </div>
      <div className="transform-container">
        <div className="tranform-image-container">
          <img
            src={MobileApp}
            className="transform-image-wrapper"
            alt="securing"
          />
        </div>
        <div className="transform-details-wrapper">
          <h2 className="transform-second-text">Mobile App Development</h2>
          <p>
            Create a unique, platform-specific mobile app. Native mobile apps
            leverage the specific capabilities of individual platforms to
            improve user engagement and satisfaction. Reduce production time and
            reach a wider audience with an app that works on multiple platforms.
            You can save time and resources using a cross-platform rather than
            native app development approach.
          </p>
        </div>
      </div>
      <div className="transform-container">
        <div className="transform-details-wrapper">
          <h2 className="transform-first-head">Custom software solutions</h2>
          <h2 className="transform-second-text">
            tailored to your specific needs
          </h2>
          <p>
            Software Development at the Petaverse is a journey from concept to
            reality. Our talented developers are well-versed in turning your
            ideas into functional and user-friendly software applications. We
            believe in crafting code that not only works flawlessly but also
            stands the test of time. Our approach combines innovative thinking
            with industry best practices to deliver solutions that not only meet
            your immediate needs but also scale effortlessly as your business
            grows. With a keen eye for detail, we ensure the end product is not
            just lines of code, but a powerful tool that transforms the way you
            operate.
          </p>
        </div>
        <div className="tranform-image-container">
          <img
            src={CustomImage}
            className="transform-image-wrapper"
            alt="securing"
          />
        </div>
      </div>
      <div className="transform-container">
        <div className="tranform-image-container">
          <img
            src={OutsourcingImage}
            className="transform-image-wrapper"
            alt="securing"
          />
        </div>
        <div className="transform-details-wrapper">
          <h2 className="transform-first-head">
            Software Development Outsoursing
          </h2>
          <h2 className="transform-second-text">
            Software Dev. <br />
            Off Your Plate.
          </h2>
          <p>
            Software development outsourcing is the process of offloading all or
            part of your software development to a third party. A skilled team
            of developers, testers, designers, and infrastructure engineers is
            assembled based on your needs. Additionally, a dedicated project
            manager tracks, manages, and ensures the success of your project.
            With our BUILD with NO DOWN PAYMENT program designed for Tech
            Founders. Let's Talk.
          </p>
        </div>
      </div>
    </div>
  );
};
