import React, { useState } from "react";

const accordionData = [
  {
    id: 1,
    title: "Network Penetration Testing Services – External or Internal",
    detail:
      "We simulate real-world attacks to provide a point-in-time assessment of vulnerabilities and threats to your network infrastructure.",
  },
  {
    id: 2,
    title: "Web Application Penetration Testing Services",
    detail:
      "In addition to the Open Source Security Testing Methodology Manual (OSSTMM) and the Penetration Testing Execution Standard (PTES) Petaverse’s application penetration testing service leverages the Open Web Application Security Project (OWASP), a comprehensive framework for assessing the security of web-based applications, as a foundation for our web application assessment methodology.",
  },
  {
    id: 3,
    title: "Mobile Application Penetration Testing Services",
    detail:
      "As the widespread use of mobile applications continues to grow, consumers and corporations find themselves facing new threats around privacy, insecure application integration, and device theft. We go beyond looking at API and web vulnerabilities to examine the risk of the application on a mobile platform. We leverage the Open Web Application Security Project (OWASP), Open Source Security Testing Methodology Manual (OSSTMM), and Penetration Testing Execution Standard (PTES) methodologies to thoroughly assess the security of mobile applications.",
  },
  {
    id: 4,
    title: "IoT and Internet-Aware Device Testing",
    detail:
      "Internet-aware devices span from ubiquitous, commercial Internet of Things (IoT) devices and systems to automotive, healthcare and mission critical Industrial Control Systems (ICS). Our testing goes beyond basic device testing to consider the entire ecosystem of the target, covering areas such as communications channels and protocols, encryption and cryptography use, interfaces and APIs, firmware, hardware, and other critical areas. Our deep dive manual testing and analysis looks for both known and previously undiscovered vulnerabilities.",
  },
  {
    id: 5,
    title: "Social Engineering Penetration Testing Services",
    detail:
      "Malicious users are often more successful at breaching a network infrastructure through social engineering than through traditional network/application exploitation. To help you prepare for this type of strike, we use a combination human and electronic methodologies to simulate attacks. Human-based attacks consist of impersonating a trusted individual in an attempt to gain information and/or access to information or the client infrastructure. Electronic-based attacks consists of using complex phishing attacks crafted with specific organizational goals and rigor in mind. Rapid7 will customize a methodology and attack plan for your organization.",
  },
  {
    id: 6,
    title: "Red Team Attack Simulation",
    detail:
      "Want to focus on your organization’s defense, detection, and response capabilities? Petaverse works with you to develop a customized attack execution model to properly emulate the threats your organization faces. The simulation includes real-world adversarial behaviors and tactics, techniques, and procedures (TTPs), allowing you to measure your security program’s true effectiveness when faced with persistent and determined attackers.",
  },
  {
    id: 7,
    title: "Wireless Network Penetration Testing Services",
    detail:
      "We leverage the Open Source Security Testing Methodology Manual (OSSTMM) and the Penetration Testing Execution Standard (PTES) as a foundation for our wireless assessment methodology, which simulates real-world attacks to provide a point-in-time assessment of vulnerabilities and threats to your wireless network infrastructure.",
  },
];

const CustomAccordion = () => {
  const [isActive, setIsActive] = useState(0);

  return (
    <div>
      <div className="pen-header-wrapper">
        <h1 className="sec-main-header">Our pen testing services</h1>
        <p className="pen-service-sub">
          Petaverse offers a range of penetration testing services to meet your
          needs. <br />
          Reach out to learn about our custom solutions.
        </p>
      </div>
      {accordionData &&
        accordionData.map((accorData, index) => {
          return (
            <div
              className="accordion"
              onClick={() => setIsActive(accorData.id)}
            >
              <div className="accordion-item">
                <div className="accordion-title">
                  <h2>{accorData.title}</h2>
                  <div>
                    {isActive === accorData.id ? (
                      <h2 className="open-close-icon">-</h2>
                    ) : (
                      <h2 className="open-close-icon">+</h2>
                    )}
                  </div>
                </div>
                {isActive === accorData.id && (
                  <div className="accordion-content">
                    <p>{accorData.detail}</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CustomAccordion;
